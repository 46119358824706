import { Controller } from "@hotwired/stimulus";
import * as htmlToImage from "html-to-image";
import FileSaver from "file-saver";

export default class extends Controller {
  static targets = [];

  filename = "";
  snipId = "";

  initialize() {}

  connect() {}

  async snip() {
    const capture = document.getElementById("capture");

    this.snipId = capture.dataset.snipId;
    this.filename = `${this.snipId}-export-${new Date().toLocaleTimeString()}.png`;

    try {
      // Ensure we get a blob from htmlToImage.toBlob
      const blob = await htmlToImage.toBlob(capture);

      // Check if the blob is valid
      if (!blob) {
        console.error("Failed to create blob for the snip.");
        return;
      }

      // Check the blob type (ensure it's a valid image type)
      if (blob.type !== "image/png") {
        console.error("Generated blob is not a valid image type.");
        return;
      }

      // Use FileSaver to save the image
      FileSaver.saveAs(blob, this.filename); // Use the dynamic filename

    } catch (error) {
      console.error("Error while generating the snip!", error);
    }
  }
}
