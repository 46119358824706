import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = [ "" ]

  initialize() {
  }

  connect() {
  }
  
  hideValidationMessage(event) {
    event.stopPropagation()
    event.preventDefault()
  }
}