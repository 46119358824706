import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "beforeTab", "afterTab", "enhance", "beforeImage", "afterImage" ]
  static values = { clickedImageId: String, displayedImageId: String }

  clickedImageId = ""

  connect() {
  }

  enhanceImage() {
    console.log("enhanced!")
  }

  before(event) {
    this.clickedImageId = event.currentTarget.dataset.imageId;
    // add a class to the selected tab
    this.showBeforeSelectedTab();
    this.showSelectedBeforeImage();
    this.finish();
  }

  after(event) {
    this.clickedImageId = event.currentTarget.dataset.imageId;
    // add a class to the selected tab
    this.showSelectedAfterTab();
    this.showSelectedAfterImage();
    this.finish();
  }

  showBeforeSelectedTab(){
    this.beforeTabTargets.forEach((tab) => {
      if (tab.dataset.imageId === this.clickedImageId) {
        tab.classList.add('active-image-tab')
      } else {
        tab.classList.remove('active-image-tab')
      }
    });
  }

  showSelectedBeforeImage(){
    this.beforeImageTargets.forEach((image) => {
      if (image.dataset.imageId === this.clickedImageId) {
        image.classList.remove('hidden')
      } else {
        image.classList.add('hidden')
      }
    });
  }

  showSelectedAfterTab(){
    this.afterTabTargets.forEach((tab) => {
      if (tab.dataset.imageId === this.clickedImageId) {
        tab.classList.add('active-image-tab')
      } else {
        tab.classList.remove('active-image-tab')
      }
    });
  }

  showSelectedAfterImage(){
    this.afterImageTargets.forEach((image) => {
      if (image.dataset.imageId === this.clickedImageId) {
        image.classList.remove('hidden')
      } else {
        image.classList.add('hidden')
      }
    });
  }

  finish(){
    console.log("finished!")
  }
}
