// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "chartkick/chart.js"
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "trix"
import "@rails/actiontext"
import Rails from '@rails/ujs'
Rails.start()

Chart.register(ChartDataLabels);

// Put this somewhere in /app/javascript
document.addEventListener("turbo:before-fetch-request", (event) => {
  event.detail.fetchOptions.headers["X-Turbo-Nonce"] =
    document.querySelector("meta[name='csp-nonce']")?.content
})

document.addEventListener("turbo:before-cache", () => {
  document.querySelectorAll("script[nonce]").forEach((element) => {
    element.setAttribute("nonce", element.nonce);
  })
})

// Trix plugin for code backticks
Trix.config.textAttributes.inlineCode = { 
  tagName: "code", 
  inheritable: true 
}

addEventListener("trix-initialize", event => {
  const element = event.target
  const { toolbarElement, editor } = element

  const blockCodeButton = toolbarElement.querySelector("[data-trix-attribute=code]")
  const inlineCodeButton = blockCodeButton.cloneNode(true)

  inlineCodeButton.hidden = true
  inlineCodeButton.dataset.trixAttribute = "inlineCode"
  blockCodeButton.insertAdjacentElement("afterend", inlineCodeButton)
  
  element.addEventListener("trix-selection-change", _ => {
    const type = getCodeFormattingType()
    blockCodeButton.hidden = type == "inline"
    inlineCodeButton.hidden = type == "block"
  })

  function getCodeFormattingType() {
    if (editor.attributeIsActive("code")) return "block"
    if (editor.attributeIsActive("inlineCode")) return "inline"

    const range = editor.getSelectedRange()
    if (range[0] == range[1]) return "block"

    const text = editor.getSelectedDocument().toString().trim()
    return /\n/.test(text) ? "block" : "inline"
  }
})

window.initMap = function(...args) {
  const event = new Event('google-maps-callback', { bubbles: true, cancelable: false });
  event.args = args
  document.dispatchEvent(event);
}
