import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "row", "table", "productsourceSelect", "productcoreSelect", "productbrandSelect", "productkindSelect", "productassortmentSelect", "productsizeSelect", "url" ]
  static values = {}

  connect() {
  }
  
  filterTableBySelectedValues(event) {
    this.showAllRows();
    this.uncolourAllVisibleRows();
    this.filterProductSource(event);
    this.filterProductCore(event);
    this.filterProductBrand(event);
    this.filterProductKind(event);
    this.filterProductAssortment(event);
    this.filterProductSize();
    this.colourAllVisibleRows();
  }

  refreshTable() {
    if(this.hasProductsourceSelectTarget) {
      this.productsourceSelectTarget.value = '';
    };
    if(this.hasProductcoreSelectTarget) {
      this.productcoreSelectTarget.value = '';
    };
    if(this.hasProductbrandSelectTarget) {
      this.productbrandSelectTarget.value = '';
    };
    if(this.hasProductkindSelectTarget) {
      this.productkindSelectTarget.value = '';
    };
    if(this.hasProductassortmentSelectTarget) {
      this.productassortmentSelectTarget.value = '';
    };
    if(this.hasProductsizeSelectTarget) {
      this.productsizeSelectTarget.value = '';
    };
    this.updateChevronNavUrls();
    this.showAllRows();
    this.uncolourAllVisibleRows();
    this.filterProductSource();
    this.filterProductCore();
    this.filterProductBrand();
    this.filterProductKind();
    this.filterProductAssortment();
    this.filterProductSize();
    this.colourAllVisibleRows();
  }

  updateChevronNavUrls() {

    this.urlTargets.forEach(a => {
      let source_params = ''
      let core_params = ''
      let brand_params = ''
      let kind_params = ''
      let assortment_params = ''
      let size_params = ''
      let href = a.href.split('?modal_open=1')[0] + '?modal_open=1';
      
      if(this.hasProductsourceSelectTarget) {
        if(!this.productsourceSelectTarget.value == '') {
          source_params = '&product_sources=' + this.productsourceSelectTarget.value.split(' ').join('+').split('&').join('%26');
        }
      };
      
      if(this.hasProductcoreSelectTarget) {
        if(!this.productcoreSelectTarget.value == '') {
          core_params = '&product_cores=' + this.productcoreSelectTarget.value.split(' ').join('+').split('&').join('%26');
        }
      };
      
      if(this.hasProductbrandSelectTarget) {
        if(!this.productbrandSelectTarget.value == '') {
          brand_params = '&product_brands=' + this.productbrandSelectTarget.value.split(' ').join('+').split('&').join('%26');
        }
      };
      
      if(this.hasProductkindSelectTarget) {
        if(!this.productkindSelectTarget.value == '') {
          kind_params = '&product_kinds=' + this.productkindSelectTarget.value.split(' ').join('+').split('&').join('%26');
        }
      };
      
      if(this.hasProductassortmentSelectTarget) {
        if(!this.productassortmentSelectTarget.value == '') {
          assortment_params = '&product_assortments=' + this.productassortmentSelectTarget.value.split(' ').join('+').split('&').join('%26');
        }
      };
      
      if(this.hasProductsizeSelectTarget) {
        if(!this.productsizeSelectTarget.value == '') {
          size_params ='&product_sizes='+ this.productsizeSelectTarget.value.split(' ').join('+').split('&').join('%26');
        }
      };
      a.href = href + source_params + core_params + brand_params + kind_params + assortment_params + size_params;
    });
  }

  filterProductSource(event) {
    if (this.hasProductsourceSelectTarget) {
      let product_sources = [...this.productsourceSelectTarget.options].filter(x => x.selected).map(x => x.value);
      if (product_sources[0] === '') {
        return 0;
      };
      this.rowTargets.forEach(row => {
        // trim whitespace from the dataset value and compare to the selected values
        const product_source = row.dataset.productSource.trim();
        if (!product_sources.includes(product_source)) {
          row.classList.add('hidden');
        };
      });
      this.updateChevronNavUrls();
    };
  };

  filterProductCore(event) {
    if (this.hasProductcoreSelectTarget) {
      let product_cores = [...this.productcoreSelectTarget.options].filter(x => x.selected).map(x => x.value);
      if (product_cores[0] === '') {
        return 0;
      };
      this.rowTargets.forEach(row => {
        const product_core = row.dataset.productCore;
        if (!product_cores.includes(product_core)) {
          row.classList.add('hidden');
        };
      });
      this.updateChevronNavUrls();
    };
  };

  filterProductBrand(event) {
    if (this.hasProductbrandSelectTarget) {
      let product_brands = [...this.productbrandSelectTarget.options].filter(x => x.selected).map(x => x.value);
      if (product_brands[0] === '') {
        return 0;
      };
      this.rowTargets.forEach(row => {
        const product_brand = row.dataset.productBrand;
        if (!product_brands.includes(product_brand)) {
          row.classList.add('hidden');
        };

        // Refresh the Product Kind dropdown if a different brand has been selected
        if (event.srcElement.name == 'product_brand') {
          this.productkindSelectTarget.value = '';
          this.filterProductKind();
        }
      });
      this.updateChevronNavUrls();
    };
  };

  filterProductKind(event) {
    if (this.hasProductkindSelectTarget) {
      let product_kinds = [...this.productkindSelectTarget.options].filter(x => x.selected).map(x => x.value);
      if (product_kinds[0] === '') {
        return 0;
      };
      this.rowTargets.forEach(row => {
        const product_kind = row.dataset.productKind;
        if (!product_kinds.includes(product_kind)) {
          row.classList.add('hidden');
        };

        // Refresh the Product Assortment dropdown if a different kind has been selected
        if (event.srcElement.name == 'product_kind') {
          this.productassortmentSelectTarget.value = '';
          this.filterProductAssortment();
        }
      });
      this.updateChevronNavUrls();
    };
  };

  filterProductAssortment(event) {
    console.log(event);
    if (this.hasProductassortmentSelectTarget) {
      let product_assortments = [...this.productassortmentSelectTarget.options].filter(x => x.selected).map(x => x.value);
      if (product_assortments[0] === '') {
        return 0;
      };
      this.rowTargets.forEach(row => {
        const product_assortment = row.dataset.productAssortment;
        if (!product_assortments.includes(product_assortment)) {
          row.classList.add('hidden');
        };
      });
      this.updateChevronNavUrls();
    };
  }

  filterProductSize() {
    if (this.hasProductsizeSelectTarget) {
      let product_sizes = [...this.productsizeSelectTarget.options].filter(x => x.selected).map(x => x.value);
      if (product_sizes[0] === '') {
        return 0;
      };
      this.rowTargets.forEach(row => {
        const product_size = row.dataset.productSize;
        if (!product_sizes.includes(product_size)) {
          row.classList.add('hidden');
        };
      });
      this.updateChevronNavUrls();
    };
  }

  showAllRows(){
    this.rowTargets.forEach((row) => {
      row.classList.remove('hidden');
    })
  }

  uncolourAllVisibleRows(){
    this.rowTargets.forEach(row => {
      row.classList.remove('even');
    })
  }

  colourAllVisibleRows(){
    let row_count = 0;
    this.rowTargets.forEach(row => {
      if (!row.classList.contains('hidden')) {
        row_count ++;
        if (row_count % 2  == 0) {
          row.classList.add('even');
        };
      };
    })
  }

  closeModal() {
    let el = document.getElementById("spotlight-modal-background");

    // If the element with the id 'spotlight-modal-background' is not found, try 'modal-background'
    if (!el) {
        el = document.getElementById("modal-background");
    }

    if (el) {
        el.remove();
    } else {
        console.warn("Modal background element not found");
    }
}

}
