import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = [ 
    "container", "searchField", "selections", "comboInput", "results", "dropdown", 
    "frameId", "minChar", "multiple",
    "table", "field", "selectionIds"
  ]
  static values = {  }

  searchId = '';
  comboboxsearch   = '';
  dropdownId = '';

  initialize() {
    this.submit = debounce(this.submit.bind(this), 200)
  }

  connect() {
    this.delayTimeout = null
    console.log("Comboboxsearch controller connected");
    this.comboInputTarget.addEventListener('input', this.handleComboInput.bind(this));

    // Add hover listener to the entire comboboxsearch-dropdown div
    this.addDropdownHoverListener();

    // add listener to detect clicks outside the dropdown
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);

    this.searchId        = this.containerTarget.id;
    this.comboboxsearch  = document.querySelector(`#${this.searchId}`);
    this.dropdown        = document.querySelector(`#${this.searchId}-dropdown`);
    this.multiple        = this.multipleTarget.textContent;
  }
  
  disconnect(event) {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const containerElement = this.containerTarget;
    const comboInputElement    = this.comboInputTarget;
    const dropdownElement = this.dropdown;

    if (!containerElement.contains(event.target) && !comboInputElement.contains(event.target) && !dropdownElement.contains(event.currentTarget.activeElement)) {
      // Click is outside the comboInput and dropdown
      this.closeDropdown();
    }
  }

  handleComboInput(event) {
    console.log("Input changed:", event.target.value);
    if (event.target.value === '') {
      this.submitAsTurboStream();
    }
  }

  addDropdownHoverListener() {
    const dropdownElement = this.element.querySelector(`#${ this.searchId }-dropdown`);
  
    if (dropdownElement) {
      dropdownElement.addEventListener('mouseover', (event) => {
        if (event.target.closest(`div#${ this.searchId }-dropdown`).contains(event.target)) {
          this.highlightSelectedValues();
        }
      });
    }
  }

  openDropdown() {
    this.dropdown.classList.remove("hidden");
    this.comboInputTarget.focus();
    this.submitAsTurboStream();
  }

  closeDropdown(event) {
    const dropdown = document.querySelector(`div#${ this.searchId }-dropdown`);
    dropdown.classList.add("hidden");
  }

  keyPress(event) {
    clearTimeout(this.delayTimeout)
    this.delayTimeout = setTimeout(() => {
      if (event.key !== "Enter") {
        event.preventDefault();

        if (this.comboInputTarget.value.length < this.minCharTarget.textContent) {
          return;
        }

        this.submitAsTurboStream();
      }
    }, 300)
  }

  selectedOption(event) {
    const selectedValue = event.currentTarget.dataset.value;
    const selectedId    = event.currentTarget.dataset.index;

    // Check if a pill with the selectedValue already exists
    const existingPill = Array.from(this.selectionsTarget.children).find(
      (child) => child.textContent.replace('×', '').trim() === selectedValue
    );
  
    if (existingPill) {
      return;
    }

    if (this.multiple === 'false') {
      this.selectionsTarget.innerHTML = '';
    }

    this.selectionsTarget.value = selectedValue;

    const pill = document.createElement('span');
    pill.className = "inline-flex items-center hover:cursor-auto px-2 py-1 bg-blue-500 text-white text-sm font-medium rounded-md mr-1";
    // Add the Stimulus data action attribute
    pill.setAttribute('data-index', selectedId);
    pill.setAttribute('data-action', 'click->comboboxsearch#removePill');
    
    pill.textContent = selectedValue;

    const closeButton = document.createElement('button');
    closeButton.className = "border-l border-white hover:cursor-pointer pl-1 ml-1 text-white text-sm focus:outline-none";
    closeButton.innerHTML = "&times;";

    pill.appendChild(closeButton);
    this.selectionsTarget.appendChild(pill);
  
    // Add the selected ID to the hidden field
    this.updateSelectionIds(selectedId, true);

    event.currentTarget.classList.add("bg-blue-500", "text-white");
  }

  removePill(event) {
    const pill          = event.currentTarget;
    const selectedId    = pill.dataset.index;
    pill.remove();
    this.updateSelectionIds(selectedId, false);
    
    const equivalentOption = Array.from(document.querySelectorAll('[role="option"]')).find(
      (option) => option.dataset.index === selectedId
    );

    if (equivalentOption) {
      equivalentOption.classList.remove("bg-blue-500", "text-white");
    }
  }

  updateSelectionIds(id, add) {
    let comboInputField = this.selectionIdsTarget;
    let ids             = comboInputField.value.split(',').filter(Boolean);

    if (this.multiple === 'true') {
      if (add) {
        if (!ids.includes(id)) {
          ids.push(id);
        }
      } else {
        ids = ids.filter(existingId => existingId !== id);
      }
      comboInputField.defaultValue = ids.join(',');
    } else {
      comboInputField.defaultValue = id;
    }
  }

  submit() {
    this.submitTarget.click()
  }
  
  hideValidationMessage(event) {
    event.stopPropagation()
    event.preventDefault()
  }

  log_all_target_values() {
    console.log("comboInputTarget value: ",       this.comboInputTarget.value);
    console.log("tableTarget value: ",            this.tableTarget.textContent);
    console.log("fieldTarget value: ",            this.fieldTarget.textContent);
    console.log("searchFieldTarget value: ",      this.searchFieldTarget.textContent);
    console.log("frameIdTarget value: ",          this.frameIdTarget.textContent);
    console.log("selectionIdsTarget value: ",     this.selectionIdsTarget.textContent);
    console.log("minCharTarget value: ",          this.minCharTarget.textContent);
    console.log("multipleTarget value: ",         this.multipleTarget.textContent);
  }

  submitAsTurboStream() {
    const selectedValues = {
        query:                               this.comboInputTarget.value,
        selectionIds:                        this.selectionIdsTarget.textContent,
        table:                               this.tableTarget.textContent,
        field:                               this.fieldTarget.textContent,
        search_field:                        this.searchFieldTarget.textContent,
        frameId:                             this.frameIdTarget.textContent
    };

    if (this.formIsNotSubmittable(selectedValues)){
      return;
    }

    this.showLoadingIcon();

    const formData = new FormData();
    this.log_all_target_values();
    if (selectedValues.query)                    formData.append("query",                    selectedValues.query);
    if (selectedValues.table)                    formData.append("table",                    selectedValues.table);
    if (selectedValues.field)                    formData.append("field",                    selectedValues.field);
    if (selectedValues.search_field)             formData.append("search_field",             selectedValues.search_field);
    if (selectedValues.frameId)                  formData.append("frame_id",                 selectedValues.frameId);

    fetch('/search', {
      method: "POST",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: formData
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html);
    })
    .then(() => {
      this.addDropdownHoverListener();
      this.highlightSelectedValues();
      this.hideLoadingIcon();
    })
  }

  showLoadingIcon() {
    let loadingIcon = this.selectionsTarget.querySelector('span');
    if (!loadingIcon) {
      loadingIcon = document.createElement('span');
      loadingIcon.classList.add("h-full", "text-red-500", "items-center");
      loadingIcon.textContent = "Loading...";
      this.selectionsTarget.classList.remove("bg-white");
      this.selectionsTarget.classList.add("bg-gray");
      this.selectionsTarget.appendChild(loadingIcon);
    }
  }

  hideLoadingIcon() {
    let loadingIcon = this.selectionsTarget.querySelector('span');
    if (loadingIcon && loadingIcon.textContent === "Loading...") {
      this.selectionsTarget.classList.remove("bg-gray");
      this.selectionsTarget.classList.add("bg-white");
      loadingIcon.remove();
    }
  }

  highlightSelectedValues() {
    const selectedIds = this.selectionIdsTarget.value.split(',').filter(Boolean);
  
    for (const selectedId of selectedIds) {
      const option = Array.from(document.querySelectorAll('[role="option"]')).find(
        (option) => {
          return option.dataset.index === selectedId; // Use `data-index` instead of `data-id`
        }
      );
      if (option) {
        option.classList.add("bg-blue-500", "text-white");
      }
    }
  }

  formIsNotSubmittable(selectedValues) {
    if (selectedValues.clientSelectionIds && selectedValues.clientSelectionIds.length > 0) {
      return false;
    }
    if (this.comboInputTarget.value.length < this.minCharTarget.textContent) {
      return true;
    }
    if (this.selectionsTarget.textContent.includes('Loading...')) {
      return true;
    }
    this.delayTimeout = setTimeout(() => {
      return false;
    }, 1000)
  }
}
